import vClickOutside from 'v-click-outside';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */
const GlobalDirectives = {
	install(Vue) {
		Vue.directive('clickOutside', vClickOutside.directive);
	}
};

export default GlobalDirectives;
