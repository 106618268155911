<template>
	<component
		:is="tag"
		:type="tag === 'button' ? nativeType : ''"
		:disabled="disabled || loading"
		@click="handleClick"
		class="btn base-button"
		:class="[
			{ 'rounded-circle': round },
			{ square: square },
			{ 'btn-block': block },
			{ 'btn-wd': wide },
			{ 'btn-icon btn-fab': icon },
			{ [`btn-${type}`]: type && !outline },
			{ [`btn-${size}`]: size },
			{ [`btn-outline-${type}`]: outline && type },
			{ 'btn-link': link },
			{ disabled: disabled && tag !== 'button' },
			{ 'btn-loading': loading },
		]"
		:aria-label="ariaLabel ? ariaLabel : label"
	>
		<slot></slot>
		<span v-if="icon && iconClass" class="btn-inner--icon">
			<i :class="iconClass"></i>
		</span>
		<span v-if="icon && iconLabel" class="btn-inner--text">{{
			iconLabel
		}}</span>
		<span v-if="label">{{ label }}</span>

		<div v-if="loading" class="loading-animation">
			<svg
				viewBox="0 0 120 30"
				xmlns="http://www.w3.org/2000/svg"
				fill="currentColor"
				class="text-60 mx-auto block"
				style="width: 50px"
			>
				<circle cx="15" cy="15" r="15">
					<animate
						attributeName="r"
						from="15"
						to="15"
						begin="0s"
						dur="0.8s"
						values="15;9;15"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
					<animate
						attributeName="fill-opacity"
						from="1"
						to="1"
						begin="0s"
						dur="0.8s"
						values="1;.5;1"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
				</circle>
				<circle cx="60" cy="15" r="9" fill-opacity="0.3">
					<animate
						attributeName="r"
						from="9"
						to="9"
						begin="0s"
						dur="0.8s"
						values="9;15;9"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
					<animate
						attributeName="fill-opacity"
						from="0.5"
						to="0.5"
						begin="0s"
						dur="0.8s"
						values=".5;1;.5"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
				</circle>
				<circle cx="105" cy="15" r="15">
					<animate
						attributeName="r"
						from="15"
						to="15"
						begin="0s"
						dur="0.8s"
						values="15;9;15"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
					<animate
						attributeName="fill-opacity"
						from="1"
						to="1"
						begin="0s"
						dur="0.8s"
						values="1;.5;1"
						calcMode="linear"
						repeatCount="indefinite"
					></animate>
				</circle>
			</svg>
		</div>
	</component>
</template>
<script>
export default {
	name: 'base-button',
	props: {
		tag: {
			type: String,
			default: 'button',
			description: 'Button html tag',
		},
		round: Boolean,
		square: Boolean,
		icon: Boolean,
		block: Boolean,
		loading: Boolean,
		wide: Boolean,
		disabled: Boolean,
		type: {
			type: String,
			default: 'default',
			description: 'Button type (primary|secondary|danger etc)',
		},
		nativeType: {
			type: String,
			default: 'button',
			description: 'Button native type (e.g button, input etc)',
		},
		size: {
			type: String,
			default: '',
			description: 'Button size (sm|lg)',
		},
		outline: {
			type: Boolean,
			description: 'Whether button is outlined (only border has color)',
		},
		link: {
			type: Boolean,
			description: 'Whether button is a link (no borders or background)',
		},
		iconClass: {
			type: String,
			description: 'What is the icon',
		},
		iconLabel: {
			type: String,
			description: 'What is the label',
		},
		label: {
			type: String,
			description: 'add a button label',
		},
		ariaLabel: {
			type: String,
			description: 'add aria label',
		},
	},
	computed: {
		hasSlot() {
			return !!this.$slots;
		},
	},
	methods: {
		handleClick(evt) {
			this.$emit('click', evt);
		},
	},
};
</script>

<style lang="scss">
.base-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.mobile-app {
	.desktop-only {
		display: none;
	}
}

.btn {
	&.square {
		border-radius: 0;
	}
}

.btn-loading {
	&.btn {
		position: relative;

		span {
			visibility: hidden;
		}
	}

	.loading-animation {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
	}
}
</style>
