import BaseInput from '@/components/Argon/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/Argon/BaseDropdown.vue';
import Card from '@/components/Argon/Cards/Card.vue';
import Modal from '@/components/Argon/Modal.vue';
import BaseButton from '@/components/Argon/BaseButton.vue';
import Badge from '@/components/Argon/Badge.vue';
import BaseCheckbox from '@/components/Argon/Inputs/BaseCheckbox.vue';
import BaseRadio from '@/components/Argon/Inputs/BaseRadio.vue';
import BaseProgress from '@/components/Argon/BaseProgress.vue';
import BasePagination from '@/components/Argon/BasePagination.vue';
import BaseAlert from '@/components/Argon/BaseAlert.vue';
import BaseNav from '@/components/Argon/Navbar/BaseNav.vue';
import BaseHeader from '@/components/Argon/BaseHeader.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover } from 'element-ui';
// import StatsCard from "@/components/Argon/Cards/StatsCard.vue";
import BaseSwitch from '@/components/Argon/BaseSwitch.vue';
// import RouteBreadcrumb from "@/components/Argon/Breadcrumb/RouteBreadcrumb";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
	install(Vue) {
		// Removify Layouts
		Vue.component('Blank', () => import('../layouts/Blank.vue'));
		Vue.component('Dashboard', () => import('../layouts/Dashboard.vue'));

		// Argon Components
		Vue.component(Badge.name, Badge);
		Vue.component(BaseAlert.name, BaseAlert);
		Vue.component(BaseButton.name, BaseButton);
		Vue.component(BaseCheckbox.name, BaseCheckbox);
		Vue.component(BaseHeader.name, BaseHeader);
		Vue.component(BaseInput.name, BaseInput);
		Vue.component(BaseDropdown.name, BaseDropdown);
		Vue.component(BaseNav.name, BaseNav);
		Vue.component(BasePagination.name, BasePagination);
		Vue.component(BaseProgress.name, BaseProgress);
		Vue.component(BaseRadio.name, BaseRadio);
		Vue.component(Card.name, Card);
		Vue.component(Modal.name, Modal);
		Vue.component(Input.name, Input);
		Vue.component('validation-provider', ValidationProvider);
		Vue.component('validation-observer', ValidationObserver);
		Vue.component(BaseSwitch.name, BaseSwitch);
		// Vue.component(StatsCard.name, StatsCard);
		// Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
		Vue.use(Tooltip);
		Vue.use(Popover);
	},
};

export default GlobalComponents;
