/**
 * These are the list of all available feature flags to be turned on or off
 */
export const FEATURE_FLAGS = [{ key: 'review_impact' }];

/**
 * These are the list of currently globally available feature in current environment,
 * which will be made available to all users by default
 */
export const FEATURE_FLAGS_ACTIVE = import.meta.env.VITE_APP_FEATURE_FLAGS
	? import.meta.env.VITE_APP_FEATURE_FLAGS.split(',')
	: [];

/**
 * These are the list of users who will be given access to the feature flags regardless
 * of the environment, as long as the feature is listed at the top
 */
export const FEATURE_FLAGS_USERS = import.meta.env.VITE_APP_FEATURE_USERS
	? import.meta.env.VITE_APP_FEATURE_USERS.split(',')
	: [];

export const getUserFeaturesByEmail = (email) => {
	// If the user is in the list of users who will be given access to the feature flags
	// return all features
	if (FEATURE_FLAGS_USERS.includes(email)) {
		return FEATURE_FLAGS;
	}

	return FEATURE_FLAGS.filter((feature) => {
		return FEATURE_FLAGS_ACTIVE.includes(feature.key);
	});
};
