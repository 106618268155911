import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

const Home = () => import('./views/Home/Index.vue');
const CreateAudit = () => import('./views/Create/CreateAudit.vue');
const CreateReviewImpact = () => import('./views/Create/CreateReviewImpact.vue');
// const Admin = () => import('./views/Admin/Index.vue');
const AuditReportType = () => import('./views/Admin/AuditReportType.vue');
const ReviewImpactReportType = () => import('./views/Admin/ReviewImpactReportType.vue');
const ReportView = () => import('./views/ReportView/Index.vue');
const CustomerView = () => import('./views/CustomerView/Index.vue');
const Login = () => import('./views/Login/Index.vue');
const NotFound = () => import('./views/Error/Index.vue');

Vue.use(VueRouter);

const router = new VueRouter({
	store,
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes: [
		{
			path: '*',
			redirect: {
				name: '404',
				params: {
					resource: 'page'
				}
			}
		},
		{
			path: '/404',
			name: '404',
			component: NotFound,
			props: true,
			meta: {
				title: "Sorry! We can't find your page.",
				layout: 'Blank',
				scopes: []
			}
		},
		{
			path: '/',
			name: 'home',
			component: Home,
			props: true,
			meta: {
				title: 'Report',
				layout: 'Blank',
				scopes: []
			}
		},
		{
			path: '/admin/',
			name: 'report',
			redirect: { name: 'report.audit' }
		},
		{
			path: '/admin/review-impact',
			name: 'report.review-impact',
			component: ReviewImpactReportType,
			props: true,
			meta: {
				title: 'Report Tool - Review Impact',
				layout: 'Dashboard',
				requiresAuth: true,
				scopes: []
			}
		},
		{
			path: '/admin/audit',
			name: 'report.audit',
			component: AuditReportType,
			props: true,
			meta: {
				title: 'Report Tool - Audit',
				layout: 'Dashboard',
				requiresAuth: true,
				scopes: []
			}
		},
		{
			path: '/admin/audit/create',
			name: 'report.audit.create',
			component: CreateAudit,
			props: true,
			meta: {
				title: 'Create a New Audit Report',
				layout: 'Dashboard',
				requiresAuth: true,
				scopes: []
			}
		},
		{
			path: '/admin/review-impact/create',
			name: 'report.review-impact.create',
			component: CreateReviewImpact,
			props: true,
			meta: {
				title: 'Create a New Report',
				layout: 'Dashboard',
				requiresAuth: true,
				scopes: []
			}
		},
		{
			path: '/admin/create',
			name: 'create',
			redirect: { name: 'report.audit.create' }
		},
		{
			path: '/admin/report/:id',
			name: 'report-view',
			component: ReportView,
			props: true,
			meta: {
				title: 'Report Tool',
				layout: 'Dashboard',
				requiresAuth: true,
				scopes: []
			}
		},
		{
			path: '/c/:id',
			name: 'customer-view',
			component: CustomerView,
			props: true,
			meta: {
				title: '',
				layout: 'Dashboard',
				scopes: []
			}
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			props: true,
			meta: {
				title: 'Login',
				layout: 'Blank',
				guest: true,
				scopes: []
			}
		}
	]
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title + ' | Removify';

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (localStorage.getItem('user') === null) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			});
		} else {
			const user =
				localStorage.getItem('user') &&
				JSON.parse(localStorage.getItem('user'));

			if (!user.account_id) {
				localStorage.removeItem('user');
			}

			if (!store.state.isLoggedIn && user) {
				// temporary auth
				store.commit('SET_IS_LOGGED_IN', true);
				store.commit('SET_USER', user);
			}

			if (user) {
				Vue.setFeatureUser(user.email)
			}

			next();
		}
	} else if (to.matched.some(record => record.meta.guest)) {
		// Guest pages
		next();
	} else {
		next();
	}
});

export default router;
