import { getUserFeaturesByEmail } from '@/utils/feature-flags'

export default {

    features: [],

    install(Vue) {
        Vue.prototype.$hasFeature = (feature) => {
            return this.features.includes(feature)
        }

        Vue.prototype.$features = () => {
            return this.features
        }

        Vue.setFeatureUser = (email) => {
            this.features = getUserFeaturesByEmail(email).map(feature => feature.key)
        }
    }
}