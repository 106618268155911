import Vue from 'vue';

const weekdays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

/**
 *  formatDate
 *  Example: Friday, 4 April 2020 || Today
 */
Vue.filter('formatDate', date => {
	var d = new Date(date.replace(' ', 'T'));
	// var today = new Date();

	// if (
	//     today.getDate() === d.getDate() &&
	//     today.getMonth() === d.getMonth() &&
	//     today.getFullYear() === d.getFullYear()
	// ) {
	//     return "Today";
	// } else {
	return (
		weekdays[d.getDay()] +
		', ' +
		d.getDate() +
		' ' +
		months[d.getMonth()] +
		', ' +
		d.getFullYear()
	);
	// }
});

/**
 *  formatMsgDate
 *  Example: Friday, 4 April 2020 || Today
 */
Vue.filter('formatMsgDate', date => {
	var d = new Date(date.replace(' ', 'T'));
	return (
		d.getDate() +
		' ' +
		months[d.getMonth()].substr(0, 3) +
		', ' +
		d.getFullYear()
	);
});

/**
 *  formatPlainDate
 *  Example: 4 April 2020 || Today
 */
Vue.filter('formatPlainDate', date => {
	var d = new Date(date.replace(' ', 'T'));
	var today = new Date();

	if (
		today.getDate() === d.getDate() &&
		today.getMonth() === d.getMonth() &&
		today.getFullYear() === d.getFullYear()
	) {
		return 'Today';
	} else {
		return (
			d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()
		);
	}
});

/**
 *  formatInvoiceDate
 *  Example: 4 April 2020
 */
Vue.filter('formatInvoiceDate', date => {
	var d = new Date(date.replace(' ', 'T'));
	return d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear();
});

Vue.filter('formatTime', date => {
	var d;
	if (typeof date === 'string') {
		// date = date.replace(/\s/, "T");
		let datetime = date.split(' ');
		let newDate = datetime[0].split('-');
		let newTime = datetime[1].split(':');
		d = new Date(
			parseInt(newDate[0]),
			parseInt(newDate[1] - 1),
			parseInt(newDate[2]),
			parseInt(newTime[0]),
			parseInt(newTime[1]),
			parseInt(newTime[2])
		);
	} else {
		d = new Date(date);
	}

	let hours = d.getHours();
	let period = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12 || 12;
	let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

	return hours + ':' + minutes + period;
});

Vue.filter('formatFullDate', date => {
	var d;
	if (typeof date === 'string') {
		// date = date.replace(/\s/, "T");
		let datetime = date.split(' ');
		let newDate = datetime[0].split('-');
		let newTime = datetime[1].split(':');
		d = new Date(
			parseInt(newDate[0]),
			parseInt(newDate[1] - 1),
			parseInt(newDate[2]),
			parseInt(newTime[0]),
			parseInt(newTime[1]),
			parseInt(newTime[2])
		);
	} else {
		d = new Date(date);
	}

	let hours = d.getHours();
	let period = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12 || 12;
	let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

	return (
		weekdays[d.getDay()] +
		', ' +
		d.getDate() +
		' ' +
		months[d.getMonth()] +
		', ' +
		d.getFullYear() +
		' at ' +
		hours +
		':' +
		minutes +
		period
	);
});

Vue.filter('capitalise', value => {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('firstName', value => {
	if (!value) return '';
	return value.split(' ', 1)[0];
});

Vue.filter('firstInitial', value => {
	if (!value) return 'U';
	return value.charAt(0).toUpperCase();
});

Vue.filter('humanFileSize', size => {
	if (size < 1024) return size + ' B';
	let i = Math.floor(Math.log(size) / Math.log(1024));
	let num = size / Math.pow(1024, i);
	let round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${num} ${'KMGTPEZY'[i - 1]}B`;
});

Vue.filter('statusName', id => {
	let name = '';
	switch (id) {
		case 'not_started':
			name = 'Preparing Submissions';
			break;
		case 'in_progress':
			name = 'In Progress';
			break;
		case 'successed':
			name = 'Successfully Removed';
			break;
		case 'failed':
			name = 'Removal Unsuccessful';
			break;
		case 'hold':
			name = 'In Queue';
			break;
		default:
			name = 'All Removals';
			break;
	}
	return name;
});

Vue.filter('statusId', name => {
	let id = '';
	switch (name) {
		case 'Preparing Submissions':
			id = 'not_started';
			break;
		case 'In Progress':
			id = 'in_progress';
			break;
		case 'Successfully Removed':
			id = 'successed';
			break;
		case 'Removal Unsuccessful':
			id = 'failed';
			break;
		case 'In Queue':
			id = 'hold';
			break;
		default:
			id = 'All Removals';
			break;
	}
	return id;
});

Vue.filter('trim', text => {
	let maxLength = 150;

	return text && text.length > maxLength
		? text
				.slice(0, maxLength)
				.split(' ')
				.slice(0, -1)
				.join(' ')
		: text;
});

Vue.filter('twitterDate', date => {
	let d = new Date(date.replace(' ', 'T'));
	let today = new Date();
	let sinceMin = Math.round((today - d) / 60000);
	let since = '';

	// Seconds
	if (sinceMin == 0) {
		let sinceSec = Math.round((today - d) / 1000);
		if (sinceSec < 10) since = '10 seconds ago';
		else if (sinceSec < 20) since = '20 seconds ago';
		else since = 'a minute ago';
	}

	// Minute
	else if (sinceMin == 1) {
		let sinceSec = Math.round((today - d) / 1000);
		if (sinceSec == 30) since = 'a minute ago';
		else if (sinceSec < 60) since = 'a minute ago';
		else since = '1 minute ago';
	}
	// Minutes
	else if (sinceMin < 45) since = sinceMin + ' minutes ago';
	// Hour
	else if (sinceMin > 44 && sinceMin < 60) since = '1 hour ago';
	// Hours
	else if (sinceMin < 1440) {
		let sinceHr = Math.round(sinceMin / 60);
		if (sinceHr == 1) since = '1 hour ago';
		else since = sinceHr + ' hours ago';
	}

	// Day (1440 = 1 day)
	else if (sinceMin > 1439 && sinceMin < 2880) since = '1 day ago';
	// Days
	else if (sinceMin > 2879 && sinceMin < 10080) {
		let sinceDay = Math.round(sinceMin / 1440);
		since = sinceDay + ' days ago';
	}

	// Week (10080 = 1 week)
	else if (sinceMin > 10079 && sinceMin < 20160) since = '1 week ago';
	// Weeks
	else if (sinceMin > 10079 && sinceMin < 43800) {
		let sinceWeek = Math.round(sinceMin / 10080);
		since = sinceWeek + ' weeks ago';
	}

	// Month (43800 = 1 month)
	else if (sinceMin > 43799 && sinceMin < 87600) since = '1 month ago';
	// Months
	else if (sinceMin > 87599 && sinceMin < 525600) {
		let sinceMonth = Math.round(sinceMin / 43800);
		since = sinceMonth + ' months ago';
	}

	// Year (525600 = 1 year)
	else if (sinceMin > 525599 && sinceMin < 1051200) since = '1 year ago';
	else {
		let sinceyear = Math.round(sinceMin / 525600);
		since = sinceyear + '  years ago';
	}

	return since;
});

Vue.filter('currency', function(amount, currency) {
	return new Intl.NumberFormat('en-AU', {
		style: 'currency',
		currency: currency
	}).format(amount);
});

Vue.filter('formatNumber', function(val) {
	if (!val) return 0;
	return new Intl.NumberFormat('en-AU').format(val);
});
