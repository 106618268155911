<template>
	<SlideYUpTransition :duration="animationDuration">
		<div
			class="modal fade"
			:class="[
				{ 'show d-block': show },
				{ 'd-none': !show },
				{ 'modal-mini': type === 'mini' },
				{ 'modal-headless': headless },
			]"
			v-show="show"
			tabindex="-1"
			role="dialog"
			:aria-hidden="!show"
			@mousedown.self="closeModalOutside"
		>
			<div
				class="modal-dialog modal-dialog-centered"
				:class="[
					{
						'modal-notice': type === 'notice',
						[`modal-${size}`]: size,
					},
					modalClasses,
				]"
			>
				<div
					class="modal-content"
					:class="[
						gradient ? `bg-gradient-${gradient}` : '',
						modalContentClasses,
					]"
				>
					<div
						class="modal-header bg-default"
						:class="[headerClasses]"
						v-if="$slots.header"
					>
						<slot name="header"></slot>
						<slot name="close-button">
							<button
								type="button"
								class="close"
								v-if="showClose"
								@click="closeModal"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span :aria-hidden="!show">
									<i class="ri ri-close"></i>
								</span>
							</button>
						</slot>
					</div>

					<div class="modal-body" :class="bodyClasses">
						<slot></slot>
					</div>

					<div
						class="modal-footer"
						:class="footerClasses"
						v-if="$slots.footer"
					>
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import swal from 'sweetalert2';

export default {
	name: 'modal',
	components: {
		SlideYUpTransition,
	},
	props: {
		show: Boolean,
		showClose: {
			type: Boolean,
			default: true,
		},
		clickOutside: {
			type: Boolean,
			default: true,
		},
		outsideCloseMessage: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				let acceptedValues = ['', 'notice', 'mini'];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Modal type (notice|mini|"") ',
		},
		modalClasses: {
			type: [Object, String],
			description: 'Modal dialog css classes',
		},
		size: {
			type: String,
			description: 'Modal size',
			validator(value) {
				let acceptedValues = ['', 'sm', 'lg', 'xl'];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		modalContentClasses: {
			type: [Object, String],
			description: 'Modal dialog content css classes',
		},
		gradient: {
			type: String,
			description: 'Modal gradient type (danger, primary etc)',
		},
		headless: {
			type: Boolean,
			description: 'Removes modal header',
		},
		headerClasses: {
			type: [Object, String],
			description: 'Modal Header css classes',
		},
		bodyClasses: {
			type: [Object, String],
			description: 'Modal Body css classes',
		},
		footerClasses: {
			type: [Object, String],
			description: 'Modal Footer css classes',
		},
		animationDuration: {
			type: Number,
			default: 200,
			description: 'Modal transition duration',
		},
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		closeModalOutside() {
			if (this.clickOutside) this.closeModal();
			else if (this.outsideCloseMessage !== '') {
				swal.fire({
					title: 'Warning',
					text: this.outsideCloseMessage,
					showCancelButton: true,
					confirmButtonText: 'Discard & Exit',
					cancelButtonText: 'Cancel',
				}).then((result) => {
					if (result.value) {
						this.closeModal();
					}
				});
			}
		},
	},
	watch: {
		show(val) {
			let documentClasses = document.body.classList;
			if (val) {
				documentClasses.add('modal-open');
			} else {
				documentClasses.remove('modal-open');
			}
		},
	},
};
</script>
<style>
.modal.show {
	background-color: rgba(0, 0, 0, 0.7);
}
</style>
