<template>
	<validation-provider
		:rules="rules"
		:name="name"
		v-bind="filterAttr($attrs)"
		v-slot="{ errors, valid, invalid, validated }"
	>
		<div class="form-group">
			<slot name="label">
				<label v-if="label" :class="labelClasses">{{ label }}</label>
			</slot>
			<div
				class="inner"
				:class="[
					{ 'input-group': hasIcon },
					{ focused: focused },
					{ 'input-group-alternative': alternative },
					{ 'has-label': label || $slots.label },
					inputGroupClasses,
				]"
			>
				<div
					v-if="prependIcon || $slots.prepend"
					class="input-group-prepend"
				>
					<span class="input-group-text">
						<slot name="prepend">
							<i :class="prependIcon"></i>
						</slot>
					</span>
				</div>
				<slot v-bind="slotData">
					<input
						v-if="type !== 'textarea'"
						ref="input"
						:value="value"
						:type="type"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						class="form-control"
						:class="[
							{
								'is-valid':
									valid && validated && successMessage,
							},
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					/>
					<textarea
						v-else
						ref="input"
						:value="value"
						:type="type"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						class="form-control"
						:class="[
							{
								'is-valid':
									valid && validated && successMessage,
							},
							{ 'is-invalid': invalid && validated },
							{
								'auto-grow': autogrow,
							},
							inputClasses,
						]"
						@input="onInput()"
					/>
				</slot>
				<div
					v-if="appendIcon || $slots.append"
					class="input-group-append"
				>
					<span class="input-group-text">
						<slot name="append">
							<i :class="appendIcon"></i>
						</slot>
					</span>
				</div>
				<slot name="infoBlock"></slot>
				<span
					v-if="limit"
					class="character-limit"
					:class="{ top: limitAtTop }"
					>{{ limit - value.length }} / {{ limit }}</span
				>
			</div>
			<slot name="success">
				<div class="valid-feedback" v-if="valid && validated">
					{{ successMessage }}
				</div>
			</slot>
			<slot name="error">
				<div
					v-if="errors[0]"
					class="invalid-feedback"
					style="display: block"
				>
					{{ errors[0] }}
				</div>
			</slot>
		</div>
	</validation-provider>
</template>
<script>
export default {
	inheritAttrs: false,
	name: 'base-input',
	props: {
		required: {
			type: Boolean,
			description: 'Whether input is required (adds an asterix *)',
		},
		group: {
			type: Boolean,
			description:
				'Whether input is an input group (manual override in special cases)',
		},
		alternative: {
			type: Boolean,
			description: 'Whether input is of alternative layout',
		},
		label: {
			type: String,
			description: 'Input label (text before input)',
		},
		error: {
			type: String,
			description: 'Input error (below input)',
		},
		successMessage: {
			type: String,
			description: 'Input success message',
			default: '',
		},
		labelClasses: {
			type: String,
			description: 'Input label css classes',
			default: 'form-control-label',
		},
		inputClasses: {
			type: String,
			description: 'Input css classes',
		},
		inputGroupClasses: {
			type: String,
			description: 'Input group css classes',
		},
		value: {
			type: [String, Number],
			description: 'Input value',
		},
		type: {
			type: String,
			description: 'Input type',
			default: 'text',
		},
		appendIcon: {
			type: String,
			description: 'Append icon (right)',
		},
		prependIcon: {
			type: String,
			description: 'Prepend icon (left)',
		},
		rules: {
			type: [String, Array, Object],
			description: 'Vee validate validation rules',
			default: '',
		},
		name: {
			type: String,
			description: 'Input name (used for validation)',
			default: '',
		},
		limit: {
			type: Number,
			description: 'Character limit',
		},
		limitAtTop: {
			type: Boolean,
			description: 'Character limit position on top',
		},
		autogrow: {
			type: Boolean,
			default: false,
			description: 'Auto resize textarea',
		},
	},
	data() {
		return {
			focused: false,
		};
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: this.updateValue,
				focus: this.onFocus,
				blur: this.onBlur,
			};
		},
		slotData() {
			return {
				focused: this.focused,
				error: this.error,
				...this.listeners,
			};
		},
		hasIcon() {
			const { append, prepend } = this.$slots;
			return (
				append !== undefined ||
				prepend !== undefined ||
				this.appendIcon !== undefined ||
				this.prependIcon !== undefined ||
				this.group
			);
		},
	},
	methods: {
		updateValue(evt) {
			let value = evt.target.value;
			this.$emit('input', value);
		},
		onInput() {
			if (this.autogrow) {
				if (this.$refs.input && this.$refs.input.value.length > 0) {
					this.$refs.input.style.height = '54px';
					this.$refs.input.style.height =
						this.$refs.input.scrollHeight > '54'
							? this.$refs.input.scrollHeight + 'px'
							: '54px';
				} else {
					this.$refs.input.style.height = '54px';
				}
			}
		},
		onFocus(evt) {
			this.focused = true;
			this.$emit('focus', evt);
		},
		onBlur(evt) {
			this.focused = false;
			this.$emit('blur', evt);
		},
		filterAttr(attr) {
			let newAttr = { ...attr };

			// we don't want this to be added to the parent div/span
			delete newAttr.placeholder;
			delete newAttr.maxlength;
			delete newAttr.minlength;
			delete newAttr['aria-label'];
			delete newAttr.disabled;
			delete newAttr.autocomplete;
			delete newAttr.size;
			delete newAttr.type;
			delete newAttr.required;
			delete newAttr.tabindex;
			delete newAttr.rows;
			delete newAttr.resize;
			delete newAttr.id;
			return newAttr;
		},
	},
};
</script>

<style lang="scss" scoped>
.auto-grow {
	resize: none;
	overflow: hidden;
	transition: all 100ms ease-in-out;
}
</style>